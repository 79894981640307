import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import Layout from "components/shared/Layout";
import HeroSection from "./Hero/HeroSection";
import SocialProof from "./_SocialProof";
import CTASection from "../shared/CTASection";
import FeaturesSection from "./Features/FeaturesSection";
import QuoteSection from "./_QuoteSection";
import FundamentallyDifferent from "./_FundamentallyDifferent";

import fbShareImageSrc from "assets/fb-share/photo-invoice-fb-share-02-26-2019.jpg";

HomePage.propTypes = {
  location: PropTypes.object,
};

export default function HomePage() {
  return (
    <Layout>
      <Helmet>
        <title>Invoicing for Photographers | PhotoInvoice</title>
        <meta property="og:url" content="https://www.photoinvoice.com" />
        <meta
          property="og:title"
          content="Stop Chasing Invoices | Pay-to-Download Invoicing for Real Estate Photographers"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={fbShareImageSrc} />
      </Helmet>

      <HeroSection />

      <SocialProof />

      <FundamentallyDifferent />

      <CTASection
        title="Get paid on time, every time."
        subtitle="Get started for free and stop chasing after payments."
        background="#FFF"
      />

      <FeaturesSection />

      <QuoteSection />

      <CTASection
        title="See for yourself, risk&#8209;free."
        subtitle="Create a sample invoice in minutes and see how it gets you paid without the hassle."
      />
    </Layout>
  );
}
