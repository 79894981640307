import React from "react";
import styled from "styled-components";

import SingleFeature from "./SingleFeature";
import EvenMore from "./EvenMore";

import payToDownloadImg from "assets/home/feature-pay-to-download.webp";
import samplesImg from "assets/home/feature-samples.webp";
import templatesImg from "assets/home/feature-templates.webp";

import noReminders from "assets/home/feature-no-reminders.png";
import flexibleDeliveryImg from "assets/home/feature-flexible-delivery.webp";

function FeaturesSection() {
  return (
    <Wrapper>
      <SingleFeature
        direction="right"
        title="Pay-to-Download"
        image={payToDownloadImg}
      >
        <p>
          Until payment is received, invoices display watermarked samples. Once
          full payment has been made, clients are able to access and download
          the finals.
        </p>
        <p>This means clients pay fast, every time.</p>
        <p>
          Want to give a trusted client access to the finals before payment?
          Each invoice can be set to deliver before or after payment.
        </p>
      </SingleFeature>

      <SingleFeature
        direction="left"
        title="Samples & Automatic Watermarks"
        image={samplesImg}
      >
        <p>
          Add sample photos, videos, and links to your invoices to give clients
          a taste of the final product before they pay.
        </p>
        <p>
          We automatically watermark sample photos to keep your assets
          protected, and can even apply custom-branded watermarks on the
          Professional Edition.
        </p>
      </SingleFeature>

      <SingleFeature
        direction="right"
        title="Flexible Content Delivery"
        image={flexibleDeliveryImg}
      >
        <p>
          Easily deliver content with your preferred service that provides a
          shareable link (Dropbox, Drive, WeTransfer, etc).
        </p>
        <p>
          Or upload & deliver photos, videos, PDFs, files, and links directly
          through PhotoInvoice on the Professional edition.
        </p>
        <p>
          Deliver exactly what you need, however works best for you and your
          clients.
        </p>
      </SingleFeature>

      <SingleFeature
        direction="left"
        title="Templates and Shortcuts"
        image={templatesImg}
      >
        <p>
          Add line items and discounts with a single click. Use keyboard
          shortcuts throughout the system to save even more time.
        </p>

        <p>
          Become an invoicing ninja and get your work done faster than ever
          before. 🥷
        </p>
      </SingleFeature>

      <SingleFeature
        direction="right"
        title="Eliminate Reminders and Followups"
        image={noReminders}
      >
        <p>Never send a payment reminder or invoice followup again.</p>
        <p>
          Since payment was required upfront and the assets have already been
          delivered, everyone’s happy… no followups required.
        </p>
      </SingleFeature>

      <EvenMore />
    </Wrapper>
  );
}

export default FeaturesSection;

const Wrapper = styled.div``;
