import React, { Component } from "react";
import styled from "styled-components";

import { ContentWrapper } from "./_ContentWrapper";
import imgSrc from "assets/home/feature-pay-to-download.webp";

class TopSection extends Component {
  render() {
    return (
      <Wrapper>
        <Title>How It Works</Title>
        <Content>
          <Copy>
            <p>
              PhotoInvoice is built around a simple idea:
              <br />
              <strong>require payment upfront.</strong>
            </p>

            <p>
              This instantly solves late payments, chasing down unpaid invoices,
              and wasting time on annoying followups.
            </p>
          </Copy>

          <ImageWrapper>
            <Image src={imgSrc} />
          </ImageWrapper>
        </Content>
      </Wrapper>
    );
  }
}

export default TopSection;

const Wrapper = styled.div``;

const Title = styled.h1`
  @media (max-width: 600px) {
    font-size: 32px;
  }
`;

const Content = styled(ContentWrapper)`
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 60px;

  @media (max-width: 600px) {
    flex-direction: column;
    padding-bottom: 30px;
  }
`;

const ImageWrapper = styled.div`
  width: 45%;

  @media (max-width: 600px) {
    margin-top: 20px;
    width: 90%;
  }
`;

const Image = styled.img`
  width: 100%;
  margin: 0;
  display: block;
`;

const Copy = styled.div`
  margin-right: 60px;
  flex: 1;
  font-size: 19px;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.8);

  @media (max-width: 700px) {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    margin-right: 0;
  }
`;
