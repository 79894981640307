import React from "react";
import styled from "styled-components";

import { colors, MAX_CONTENT_WIDTH } from "Constants";

import {
  PiHandHeartDuotone,
  PiCoinsDuotone,
  PiCurrencyEurDuotone,
  PiInvoiceDuotone,
  PiCloudCheckDuotone,
  PiImagesDuotone,
  PiChartLineUpDuotone,
  PiEnvelopeDuotone,
  PiDesktopDuotone,
} from "react-icons/pi";

export default function EvenMore() {
  return (
    <Wrapper>
      <Title>And Even More...</Title>
      <Items>
        <Item>
          <Icon>
            <PiInvoiceDuotone />
          </Icon>
          <Text>Unlimited invoices &&nbsp;clients</Text>
        </Item>
        <Item>
          <Icon>
            <PiCloudCheckDuotone />
          </Icon>
          <Text>Unlimited media storage</Text>
        </Item>
        <Item>
          <Icon>
            <PiImagesDuotone />
          </Icon>
          <Text>Deliver photos, videos, PDFs, links, and files</Text>
        </Item>
        <Item>
          <Icon>
            <PiHandHeartDuotone />
          </Icon>
          <Text>Accept tips</Text>
        </Item>
        <Item>
          <Icon>
            <PiCoinsDuotone />
          </Icon>
          <Text>Collect sales tax</Text>
        </Item>
        <Item>
          <Icon>
            <PiCurrencyEurDuotone />
          </Icon>
          <Text>Multiple currencies supported</Text>
        </Item>
        <Item>
          <Icon>
            <PiChartLineUpDuotone />
          </Icon>
          <Text>Reports</Text>
        </Item>
        <Item>
          <Icon>
            <PiEnvelopeDuotone />
          </Icon>
          <Text>Custom email templates</Text>
        </Item>
        <Item>
          <Icon>
            <PiDesktopDuotone />
          </Icon>
          <Text>Use your own domain</Text>
        </Item>
      </Items>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 100px 0 50px;

  @media (max-width: 640px) {
    padding: 60px 0;
  }

  @media (max-width: 460px) {
    padding: 40px 0;
  }
`;

const Title = styled.h3`
  margin: 0;
  line-height: 1.4;
  color: ${colors.primary};
  font-size: 30px;
  text-align: center;

  @media (max-width: 900px) {
    text-align: center;
  }

  @media (max-width: 640px) {
    font-size: 28px;
  }

  @media (max-width: 460px) {
    font-size: 24px;
  }
`;

const Items = styled.div`
  width: ${MAX_CONTENT_WIDTH};
  max-width: 96%;
  margin: 30px auto 0;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const Item = styled.div`
  width: 180px;
  margin-bottom: 1rem;

  @media (max-width: 600px) {
    width: 120px;
  }
`;

const Text = styled.div`
  margin-top: 0.25rem;
  font-size: 17px;
  text-align: center;
  line-height: 1.2;

  @media (max-width: 600px) {
    font-size: 15px;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  padding: 1rem;
  color: ${colors.primary};

  @media (max-width: 600px) {
    font-size: 36px;
  }
`;
