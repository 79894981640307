import React from "react";
import styled from "styled-components";

import CustomerQuote from "components/shared/CustomerQuote";
import newListingMediaLogo from "assets/home/logo-new-listing-media.jpg";

export default function QuoteSection() {
  return (
    <Wrapper>
      <Content>
        <CustomerQuote
          author="David @ New Listing Media"
          quote="If you are looking for a new delivery system and invoicing
            with pay-to-download, check out PhotoInvoice. We transitioned and I'm so glad we made the switch!"
          logo={newListingMediaLogo}
        />
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 50px 0 30px;

  @media (max-width: 700px) {
    padding: 20px;
  }
`;

const Content = styled.div`
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media (max-width: 930px) {
    flex-direction: column;
    width: 700px;
  }
`;
